define('hc2-ui/components/apps/integrations/inxmail-app-settings', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    config: Ember.inject.service(),
    i18n: Ember.inject.service(),

    selectedClientLists: Ember.computed('inxmailClientId', function () {
      this.set('selectedInxmailList', null);
      return this.get("store").query('inxmail-list', {
        platformId: this.get('config.platform.id')
      });
    }),

    selectedClientListOrder: ['name:asc'],
    selectedClientListsOrdered: Ember.computed.sort('selectedClientLists', 'selectedClientListOrder'),

    selectedClientListAttributes: Ember.computed('appData.appIntegrations.inxmail.list.id', function () {
      var _this = this;

      var listId = this.get('appData.appIntegrations.inxmail.list.id');
      if (listId) {
        if (this.get("listAttributesCache")[listId]) {
          return _emberData.default.PromiseObject.create({ promise: new Promise(function (r) {
              r(_this.get("listAttributesCache")[listId]);
            }) });
        } else {
          return this.get("store").query('inxmail-list-attributes', {
            platformId: this.get('config.platform.id'),
            clientId: this.get('appData.appIntegrations.inxmail.list.clientId'),
            recipientListId: listId
          }).then(function (attributes) {
            _this.get("listAttributesCache")[listId] = attributes;
            return attributes;
          });
        }
      }
      return null;
    }),
    listAttributesCache: {},
    selectedClientListAttributesOptions: Ember.computed('selectedClientListAttributes', function () {

      if (this.get('selectedClientListAttributes')) {
        return _emberData.default.PromiseArray.create({
          promise: this.get('selectedClientListAttributes').then(function (attrs) {
            var options = [];
            attrs.forEach(function (v) {
              options.push({
                id: v.get('name'),
                label: v.get('name'),
                type: v.get('type')
              });
            });
            return options;
          })
        });
      }
      return [];
    }),

    actions: {
      activateInxmail: function activateInxmail() {
        this.set('showInxmailForm', true);
      },
      deactivateInxmail: function deactivateInxmail() {
        this.get("appData.appIntegrations").then(function (appIntegrations) {
          appIntegrations.set("inxmail", { active: false });
          appIntegrations.save();
        });
      },
      activateInxmailList: function activateInxmailList(list) {
        var _this2 = this;

        this.get("store").query('inxmail-list-attributes', {
          platformId: this.get('config.platform.id'),
          recipientListId: list.get("id")
        }).then(function (attributes) {
          _this2.get("listAttributesCache")[list.get("id")] = attributes;
          _this2.get("appData.appIntegrations").then(function (appIntegrations) {
            appIntegrations.set("inxmail", {
              active: true,
              list: {
                id: list.get("id"),
                name: list.get("name"),
                mapping: _this2.getDefaultMapping(attributes, _this2.get("appData"))
              }
            });
            appIntegrations.save();
          });
        });
      },
      deleteMapping: function deleteMapping(mappingItem) {
        var mapping = this.get('appData.appIntegrations.inxmail.list.mapping');
        if (mapping) {
          mapping.removeObject(mappingItem);
        }
      },
      addMapping: function addMapping() {
        var mapping = this.get('appData.appIntegrations.inxmail.list.mapping');
        if (mapping) {
          mapping.pushObject({ source: null, dest: null });
        }
      },
      saveMapping: function saveMapping() {
        var _this3 = this;

        this.get("store").peekRecord("appIntegration", this.get("appData.appIntegrations.id")).save().then(function () {
          window.alert(_this3.get("i18n").t("emarsys.mappingSavedSuccessfully"));
        });
      }
    },

    appFields: Ember.computed('subAppsLoaded', 'appData.contactData', "appData.contactData.extraFromElements", "appData.questions", function () {
      var _this4 = this;

      var fields = [];

      Object.keys(this.get("standardFieldMappings")).forEach(function (k, v) {
        if (k !== 'locale' && k !== 'hiddenfield') {
          fields.addObject({
            id: k,
            label: _this4.get("i18n").t('sweepstake.contact.' + k)
          });
        }
      });
      fields.addObject({
        id: 'locale',
        label: this.get("i18n").t('locale')
      });
      fields.addObject({
        id: 'hiddenfield',
        label: this.get("i18n").t('question.type.hiddenfield')
      });

      this.get('allApps').forEach(function (app) {

        var subAppIdPrefix = '';
        var subAppLabelPrefix = '';
        if (app.id !== _this4.get('appData.id')) {
          subAppIdPrefix = "a" + app.id + '-';
          subAppLabelPrefix = "SubApp " + app.get('childAppIndex') + " ";
        }

        if (app.get("questions")) {
          app.get("questions").forEach(function (e, i) {
            if (e.type !== "freetext") {
              fields.addObject({
                id: subAppIdPrefix + "q" + i,
                label: subAppLabelPrefix + _this4.get("i18n").t("poll.questions") + " " + (i + 1) + ": " + e.label,
                description: _this4.get("i18n").t("question.type." + e.type)
              });
            }
          });
        }

        if (app.get("contactData.extraFromElements")) {
          app.get("contactData.extraFromElements").forEach(function (e, i) {
            if (e.type !== "freetext") {
              fields.addObject({
                id: subAppIdPrefix + "c" + i,
                label: subAppLabelPrefix + _this4.get("i18n").t("sweepstake.contact.form") + " " + (i + 1) + ": " + e.label,
                description: _this4.get("i18n").t("question.type." + e.type)
              });
            }
          });
        }
      });

      return fields;
    }),
    childAppsSorting: ['childAppIndex:asc'],
    sortedChildApps: Ember.computed.sort('appData.childApps', 'childAppsSorting'),
    allApps: Ember.computed('appData', 'sortedChildApps', function () {
      var _this5 = this;

      var apps = [];
      apps.push(this.get("appData"));
      this.get('sortedChildApps').forEach(function (app) {
        apps.push(app);
      });
      this.get('appData.childApps').then(function () {
        _this5.set("subAppsLoaded", true);
      });
      return apps;
    }),

    defaultMapping: Ember.computed('selectedClientListAttributes', 'appData.contactData', "appData.contactData.extraFromElements", "appData.questions", function () {
      var _this6 = this;

      if (this.get('selectedClientListAttributes')) {
        return _emberData.default.PromiseArray.create({
          promise: this.get('selectedClientListAttributes').then(function (attributes) {
            return _this6.getDefaultMapping(attributes, _this6.get("appData"));
          })
        });
      }
      return [];
    }),
    getDefaultMapping: function getDefaultMapping(attributes, appData) {
      var mappings = [];
      var standardFieldMappings = this.get("standardFieldMappings");
      Object.keys(standardFieldMappings).forEach(function (k) {
        if (standardFieldMappings[k]) {
          // hasStandarMapping
          if (appData.get("contactData." + k)) {
            // is active in app
            standardFieldMappings[k].forEach(function (mapping) {
              // check if any mapping is available in listFields
              var listAttr = attributes.filterBy('name', mapping);
              if (listAttr.length) {
                mappings.addObject({
                  source: k,
                  dest: mapping
                });
              }
            });
          }
        }
      });
      return mappings;
    },

    standardFieldMappings: {
      salutation: null, //['anrede', 'salutation'], // male, female - The value [Herr] for attribute [anrede] is not parsable
      firstName: ['vorname', 'firstname', 'Vorname'],
      lastName: ['name', 'lastname', 'Nachname'],
      name: null,
      email: ['email'],
      phone: null,
      mobile: null,
      birthdate: null, //DD.MM.YYYY
      street: null,
      streetNr: null,
      zip: ['plz', 'zipcode'],
      city: null,
      country: ['land', 'country'],
      locale: ['sprache', 'language'],
      hiddenfield: null
    }

  });
});